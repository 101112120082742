import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  trackyourclaimicon,
  Upload_doc_Icon,
  Right_arrow_icon,
} from "../Assets/index";
import HeaderTabs from "../components/HeaderTabs";
import ClaimModal from "../components/Claim_status_Modal";
import Header from "../components/Header";
import NoDataFound from "../components/NoDataFound";
import CardLoader from "../components/CardLoader";
import { AppContext } from "../ContextApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { NotokenFound } from "../Utils";
import ClaimsFeatures from "../components/ClaimsFeatures";
import Wellness_Benefits from "./Wellness_Benefits";
import HeroSection from "../components/HeroSection";
import Wellness_corner from "./Wellness_corner";
import { mainAPikey } from "../ContextApi";

const Claimstatus = () => {
  const [claimStatusData, setclaimStatusData] = useState([]);
  const [claimModalData , setClaimModalData] = useState([]);
  const [claimHistory, setClaimsHistory] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [dataMsg, setdataMsg] = useState("");
  const [keyvalue, setkeyvalue] = useState(0);
  const [show, setShow] = useState(false);
  const [activeNoDataComponent, setactiveNoDataComponent] = useState(1);
  const navigate = useNavigate();
  const [firstGroup, setfirstGroup] = useState(null);
  const [secondGroupData, setsecondGroupData] = useState(null);
  const [itemsPerGroup, setitemsPerGroup] = useState(null);
  const [openItem, setOpenItem] = useState(0);
  const {
    activeElementId,
    policytypeidcontext,
    requestOptions,
    setRequestOptions,
  } = useContext(AppContext);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))

  const handleClose = () => setShow(false);

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(authorizationToken);
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    setactiveNoDataComponent(1);
    const fetchData = async () => {
      if (activeElementId && token) {
        setisLoading(true);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/${mainAPikey}/employee/claim-status-list/?policy_id=${activeElementId}`,
            requestOptions
          );

          const data = await handleTokenInResponse(response);
          const res = await data.json();
          if (res.status === 429) {
            toast.error("Oops! You have reached maximum limit, please try again after sometime.");
          }
          else if (res.success) {
            setclaimStatusData(Object.values(res.data.claims_status));
            setactiveNoDataComponent(
              Object.values(res.data.claims_status).length
            );
            setdataMsg(res.message);
            setClaimsHistory(
              Object.values(res.data.claims_status)[0]?.claims_statuses
            );
          } else {
            setclaimStatusData(res.data);
            setactiveNoDataComponent(0);
            setdataMsg(res.message);
          }

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setisLoading(false);
        }
      }
    };

    fetchData();
  }, [activeElementId, token]);

  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    if (claimStatusData) {
      setitemsPerGroup(Math.ceil(claimStatusData.length / 2));
      setfirstGroup(claimStatusData?.slice(0, itemsPerGroup));
      setsecondGroupData(
        claimStatusData?.slice(itemsPerGroup, claimStatusData?.length)
      );
    }
  }, [claimStatusData, itemsPerGroup]);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const handleAccordionClick = (eventKey) => {
    if (openItem === eventKey) {
      setOpenItem(null);
    } else {
      setOpenItem(eventKey);
    }
  };

  const handleModal = (index , claimData) => {
    setClaimModalData(claimData)
    setShow(true);
  };

  return (
    <>
      <Header />
      <HeroSection/>
      <div className="claim-status-main-cover-wrapper">
        <div className="claim-status-header-cover">
          <div className="claims-tabs-section-cover-wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="claims-header-tabs">
                  <ClaimsFeatures />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="claims-content-cover-wrapper">
                  {isLoading ? (
                    <CardLoader />
                  ) : claimStatusData && claimStatusData.length > 0 ? (
                    <div className="claim-status-content-cover">
                      <div className="container">
                        <div className="claim-status-header-title">
                          <h4 className="text-center">My Claim Status</h4>
                        </div>
                        <div className="claim-status-content-box">
                          <div className="row d-flex justify-content-center">
                            <div className="claim-status-accordion-main-wrapper">
                              <Accordion defaultActiveKey={openItem}>
                                <div className="row">
                                  {firstGroup && (
                                    <div className="col-lg-6">
                                      {firstGroup.map((claimData, index) => {
                                        const eventkey = index;
                                        return (
                                          <div className="col-lg-12">
                                            <div className="claim-accordion-main-box">
                                              <Accordion.Item
                                                eventKey={eventkey}
                                                onClick={() =>
                                                  handleAccordionClick(index)
                                                }
                                              >
                                                <Accordion.Header>
                                                  <div
                                                    className="claim-status-details-card"
                                                    key={index}
                                                  >
                                                    <div className="test-main-header">
                                                      <h5 className="policy-name">
                                                        {claimData.patient_name}
                                                      </h5>
                                                    </div>
                                                    <div className="test-content-details">
                                                      <p className="claim-number">
                                                        {
                                                          claimData.insurance_claim_no
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <div className="test-detail-main-page-cover">
                                                    <div className="test-all-details-wrapper">
                                                      <div className="test-all-details-cover">
                                                        <div className="test-details-box">
                                                          <div className="test-content-details">
                                                            <p className="test-claim-number"></p>
                                                            <div className="test-content-list">
                                                              <ul>
                                                                <li>
                                                                  Hospital Name
                                                                  :
                                                                  <span className="hospital-name">
                                                                    {
                                                                      claimData?.hospital_name
                                                                    }
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  Intimation
                                                                  Date :
                                                                  <span className="intimation-date">
                                                                    {
                                                                      claimData?.claim_registered_date
                                                                    }
                                                                  </span>
                                                                </li>
                                                                <li>
                                                                  Admission Date
                                                                  :
                                                                  <span className="admission-date">
                                                                    {
                                                                      claimData?.date_of_hospitalization
                                                                    }
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  Latest Update
                                                                  Dates :
                                                                  <span className="hospital-name">
                                                                    {
                                                                      claimData?.status_created_at
                                                                    }
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li className="claim-status-detail">
                                                                  Claim Status :
                                                                  <span className="paid-status">
                                                                    {
                                                                      claimData.status
                                                                    }
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </div>
                                                          <div className="test-footer-cover">
                                                            <div className="test-footer-box">
                                                              <div className="test-track-claim">
                                                                <div
                                                                  className="track_your_claim_toggle_box"
                                                                  onClick={() =>
                                                                    handleModal(
                                                                      index , claimData
                                                                    )
                                                                  }
                                                                >
                                                                  <div className="test-track-icon-box">
                                                                    <img
                                                                      src={
                                                                        trackyourclaimicon
                                                                      }
                                                                      alt
                                                                    />
                                                                  </div>
                                                                  <p>
                                                                    Track Your
                                                                    Claim
                                                                  </p>
                                                                </div>
                                                                <a href>
                                                                  <div className="test-track-icon-box">
                                                                    <img
                                                                      src={
                                                                        Upload_doc_Icon
                                                                      }
                                                                      alt
                                                                    />
                                                                  </div>
                                                                  <p>
                                                                    Upload
                                                                    Document
                                                                  </p>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {claimHistory && (
                                                            <div className="claims-status-history-main-wrapper-cover mt-4">
                                                              <div className="claims-status-box-wrapper">
                                                                <h4>
                                                                  Claims History
                                                                </h4>
                                                                {claimHistory.map(
                                                                  (
                                                                    claims_statusHistory,
                                                                    ind
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        className="claims-status-box"
                                                                        key={
                                                                          ind
                                                                        }
                                                                      >
                                                                        <div className="claim-status-history-date-box">
                                                                          <h5>
                                                                            {
                                                                              claims_statusHistory.created_at
                                                                            }
                                                                          </h5>
                                                                        </div>
                                                                        <div className="arrow-img-box">
                                                                          <img
                                                                            src={
                                                                              Right_arrow_icon
                                                                            }
                                                                          ></img>
                                                                        </div>
                                                                        <div className="claims-status">
                                                                          {
                                                                            claims_statusHistory.status
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {secondGroupData && (
                                    <div className="col-lg-6">
                                      {secondGroupData.map(
                                        (claimData, index) => {
                                          const eventkey =
                                            index + 1 + firstGroup?.length;

                                          return (
                                            <div className="col-lg-12">
                                              <div className="claim-accordion-main-box">
                                                <Accordion.Item
                                                  eventKey={eventkey}
                                                  onClick={() =>
                                                    handleAccordionClick(index)
                                                  }
                                                >
                                                  <Accordion.Header>
                                                    <div
                                                      className="claim-status-details-card"
                                                      key={index}
                                                    >
                                                      <div className="test-main-header">
                                                        <h5 className="policy-name">
                                                          {
                                                            claimData.patient_name
                                                          }
                                                        </h5>
                                                      </div>
                                                      <div className="test-content-details">
                                                        <p className="claim-number">
                                                          {
                                                            claimData.insurance_claim_no
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    <div className="test-detail-main-page-cover">
                                                      <div className="test-all-details-wrapper">
                                                        <div className="test-all-details-cover">
                                                          <div className="test-details-box">
                                                            <div className="test-content-details">
                                                              <p className="test-claim-number"></p>
                                                              <div className="test-content-list">
                                                                <ul>
                                                                  <li>
                                                                    Hospital
                                                                    Name :
                                                                    <span className="hospital-name">
                                                                      {
                                                                        claimData?.hospital_name
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <ul>
                                                                  <li>
                                                                    Intimation
                                                                    Date :
                                                                    <span className="intimation-date">
                                                                      {
                                                                        claimData?.claim_registered_date
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                  <li>
                                                                    Admission
                                                                    Date :
                                                                    <span className="admission-date">
                                                                      {
                                                                        claimData?.date_of_hospitalization
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <ul>
                                                                  <li>
                                                                    Latest
                                                                    Update Dates
                                                                    :
                                                                    <span className="hospital-name">
                                                                      {
                                                                        claimData?.status_created_at
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <ul>
                                                                  <li className="claim-status-detail">
                                                                    Claim Status
                                                                    :
                                                                    <span className="paid-status">
                                                                      {
                                                                        claimData.status
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                            <div className="test-footer-cover">
                                                              <div className="test-footer-box">
                                                                <div className="test-track-claim">
                                                                  <div
                                                                    className="track_your_claim_toggle_box"
                                                                    onClick={() =>
                                                                      handleModal(
                                                                        index , claimData
                                                                      )
                                                                    }
                                                                  >
                                                                    <div className="test-track-icon-box">
                                                                      <img
                                                                        src={
                                                                          trackyourclaimicon
                                                                        }
                                                                        alt
                                                                      />
                                                                    </div>
                                                                    <p>
                                                                      Track Your
                                                                      Claim
                                                                    </p>
                                                                  </div>
                                                                  <a href>
                                                                    <div className="test-track-icon-box">
                                                                      <img
                                                                        src={
                                                                          Upload_doc_Icon
                                                                        }
                                                                        alt
                                                                      />
                                                                    </div>
                                                                    <p>
                                                                      Upload
                                                                      Document
                                                                    </p>
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {claimHistory && (
                                                              <div className="claims-status-history-main-wrapper-cover mt-4">
                                                                <div className="claims-status-box-wrapper">
                                                                  <h4>
                                                                    Claims
                                                                    History
                                                                  </h4>
                                                                  {claimHistory.map(
                                                                    (
                                                                      claims_statusHistory,
                                                                      ind
                                                                    ) => {
                                                                      return (
                                                                        <div
                                                                          className="claims-status-box"
                                                                          key={
                                                                            ind
                                                                          }
                                                                        >
                                                                          <div className="claim-status-history-date-box">
                                                                            <h5>
                                                                              {
                                                                                claims_statusHistory.created_at
                                                                              }
                                                                            </h5>
                                                                          </div>
                                                                          <div className="arrow-img-box">
                                                                            <img
                                                                              src={
                                                                                Right_arrow_icon
                                                                              }
                                                                            ></img>
                                                                          </div>
                                                                          <div className="claims-status">
                                                                            {
                                                                              claims_statusHistory.status
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Accordion>
                            </div>
                          </div>
                          <div className="modal-cover">
                            <ClaimModal
                              show={show}
                              handleClose={handleClose}
                              onHide={() => setShow(false)}
                              keyvalue={keyvalue}
                              data={claimStatusData}
                              claimHistory={claimHistory}
                              claimModalData={claimModalData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeNoDataComponent === 0 ? (
                    <NoDataFound msg={dataMsg} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
    position="top-center"
/>
    </>
  );
};

export default Claimstatus;
