import React, { useContext, useState } from "react";
import { brokerlogo } from "../Assets";

import HeaderTabs from "./HeaderTabs";
import HeaderTestTabs from "./HeaderTabsUnique";
import "swiper/css";

const HeroSection = ({ scrollToSectionRef, type }) => {
  return (
    <>
      <div className="hero-section-main-cover-wrapper">
        <div className="broker-icon-box">
          <img src={brokerlogo}></img>
        </div>
        {type == 1 ? (
          <HeaderTestTabs scrollToSectionRef={scrollToSectionRef} />
        ) : (
          <HeaderTabs scrollToSectionRef={scrollToSectionRef} />
        )}
      </div>
    </>
  );
};

export default HeroSection;
